$platform-name: 'afghanaid';

// Colours
$brand-green: rgb(123, 192, 67);
$accessible-green: #498418;
$brand-primary: $brand-green;
$brand-secondary: $brand-green;

// Create greyscale
$black: #333;
$grey-dark: #333;
$grey: #464a4c;
$grey-light: #636c72;
$grey-lighter: rgb(240,240,240);
$grey-lightest: #f7f7f9;
$white: #fff;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1280px
) !default;

// Spacers
$spacer: 1rem !default;
$spacer-y: $spacer !default;
$spacer-x: $spacer !default;

// Transitions
$transition-duration: 0.3s !default;

// Logo
$logo: "logoNoStrapline.png";
$logo-width: 250px;
$logo-height: 45px;
$logo-mobile-width: $logo-width * .75;
$logo-mobile-height: $logo-height * .75;


// Typography
$font-family-base: "plantin", sans-serif;
$headings-font-family: "acumin-pro-condensed", sans-serif !default;
$font-weight-normal: 300;
$font-weight-bold: 800;
$font-size-base: 1.1rem !default;
$line-height-base: 1.4;
$font-size-large: 1.25em !default;
$font-size-medium: 1.1em !default;
$font-size-small: 0.875em !default;

$font-size-h1: 2em !default;
$font-size-h2: 1.75em !default;
$font-size-h3: 1.5em !default;
$font-size-h4: 1.25em !default;
$font-size-h5: 1em !default;
$font-size-h6: 1em !default;

$headings-margin-top: 1rem; // This will only affect headings within post content
$headings-line-height: 1;


// Links
$link-colour: $black;
//$link-decoration: underline;
$link-decoration-colour: $brand-primary;


// Buttons
$btn-text-colour: $black;
$btn-padding-x: 1em !default;
$btn-padding-y: 0.5em !default;
$btn-font-family: $headings-font-family !default;
$btn-font-weight: $font-weight-bold !default;
$btn-font-size: inherit !default;
$btn-text-transform: capitalize;
$btn-hover-style: lighten; // darken or lighten the button on hover


//$btn-letter-spacing: 1.1px !default;

// Social icons (https://github.com/RaisingITDev/fresco/wiki/social-icons)
// Social network colours - use these by changing $social-icons-use-brand-colours to true
$twitter-brand-colour: #1da1f2 !default;
$facebook-brand-colour: #3b5998 !default;
$youtube-brand-colour: #ff0000 !default;
$instagram-brand-colour: #833ab4 !default;
$linkedin-brand-colour: #0077b5 !default;
$pinterest-brand-colour: #bd081c !default;
$tripadvisor-brand-colour: #00af87 !default;
$vimeo-brand-colour: #1ab7ea !default;

$social-icons-colour: $black; // A colour, or 'brand'
$social-icons-border-radius: 20px;

// Form elements
$input-padding-y: 0.375rem !default;
$input-padding-x: 0.75rem !default;

// Card
$card-border: none ;
$card-footer-on: true;
$card-footer-background-colour: transparent;

// Card side by side
$card-side-by-side-vertical-text-position: center !default; // top, center or bottom

// Menu admin
$menu-admin-enabled: false;

// Header
$header-background-colour: white;
$header-content-margin-top: $spacer;
$header-content-margin-bottom: $spacer;
//$header-absolute: false !default; // true if you want the header to appear transparently over banner images

// Header content
$header-content-max-width: 100%;

// Main call to action
//$cta-donate-on-appeal: true; // Do you want to show the main cta donate button when on appeal post/donate form?

// Search - input
$header-search-input-margin-right: -43px;
$header-search-input-padding: $input-padding-y 43px $input-padding-y $input-padding-x;
$header-search-input-max-width: 135px;
$header-search-input-border-colour: $grey-light;
$header-search-input-border-width: 0px; // t-r-b-l
$header-search-input-placeholder-colour: $grey-light;
$header-search-input-background-colour: $grey-lighter;


// Search - button
$header-search-button-background-colour: transparent;
$header-search-button-icon-colour: $grey-light;

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $header-background-colour;
$header-sticky-logo-width: $logo-width * .75;
$header-sticky-logo-height: $logo-height * .75;
$header-sticky-header-content-margin-top: $header-content-margin-top;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom;
$header-sticky-search-enabled: true !default;

// Navigation
$nav-background-colour: transparent;
$nav-font-family: $headings-font-family;
$nav-breakpoint: map-get($breakpoints, xl) !default;

// Top level items
$nav-top-level-item-padding: 10px 10px !default;
$nav-top-level-item-colour: $black;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-font-size: 1em;
$nav-top-level-chevrons-enabled: true;
$nav-top-level-chevrons-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;

// Submenus
//$nav-submenu-width: 220px !default;
$nav-submenu-background-colour: white;
$nav-submenu-item-colour: $black;
//$nav-submenu-item-hover-background-colour: $brand-primary;

// Burger button
// $burger-btn-background-colour: $btn-background-colour !default;
// $burger-btn-text-colour: $btn-text-colour !default;
// $burger-btn-text-transform: $btn-text-transform !default;
$burger-btn-icon-breakpoint: xs; // What viewport width do you want to remove the 'menu' text from the burger button?
// $burger-btn-bar-breakpoint: null !default; // What viewport width do you want the burger button to pop under the header full width?

// Nav normal
$nav-normal-contents-max-width: 100%;
$nav-normal-align-items: right; // left, center, right
$nav-normal-margin-top: 25px !default;
$nav-normal-margin-bottom: 25px !default;
$nav-normal-mobile-submenu-background-colour: $grey-lighter;

// Carousel
$carousel-max-width: 100%;
$carousel-image-overlay: rgba(0,0,0,0.5); // Add a colour value or gradient here
$carousel-heading-font-size: 2.5em;
$carousel-summary-colour: white !default;
$carousel-summary-font-size: 2em;
$carousel-read-more-enabled: false !default;
$carousel-details-padding: $spacer *2.5 !default;
$carousel-details-position-y: bottom; // top, center, bottom
$carousel-details-background-colour: transparent;
$carousel-details-max-width: 600px;
// $carousel-details-margin-y: 40px;
// $carousel-details-margin-x: 40px;
$carousel-heading-colour: $brand-primary;
$carousel-breakpoint: map-get($breakpoints, xs);
// $carousel-yt-background-enabled: true;
// $carousel-yt-background-size: cover;
// $carousel-yt-cover-padding-bottom: 

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Home intro
$home-intro-background-colour: transparent;
$home-intro-margin-top: 0;
$home-intro-font-size: 1.25em !default;

// Home features
//$home-features-padding-bottom: $spacer * 3;
//$home-features-cards-entry-animation-prefab: 1;

// Home feeds
$home-feeds-cards-entry-animation-prefab: 1;

// Feeds title
$feeds-title-text-align: center; // left, center, right
$feeds-title-text-transform: uppercase;

// Impact stats
$impact-stats-background-colour: #000;
$impact-stat-padding: 0px 10px;
$impact-stats-background-image: "mountains.jpeg";
$impact-stats-figure-colour: $white;
$impact-stats-background-image-opacity: .75;
$impact-stats-heading-margin-bottom: 0;
$impact-stats-summary-font-size: $font-size-medium;
$impact-stats-heading-font-size: $font-size-h1;

// Context container
$context-container-background-colour: $grey-lighter;

// Page title
//$page-title-font-size: $font-size-h3;

// Page title over banner
//$page-title-over-banner-enabled: true;
// $page-title-over-banner-background-colour: transparent; // Add a background colour so that title is always legible as it appears over a photo
// $page-title-over-banner-breadcrumb-enabled: false; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird

// Sidebar
//$sidebar-background-colour: $grey-lighter;
//$sidebar-padding: $spacer *2;

// Associated lists
$associated-most-read-enabled: false;
$associated-max-number-of-posts: 3;

// Top fundraisers
$top-fundraisers-enabled: false !default;

// Donation form
$donation-form-frequency-tabs: true; // Have you added the DonationTarrifsAndTabs.js custom layout?

// Donation amounts
$donation-amount-figure-font-size: $font-size-h3;
$donation-amount-figure-colour: $black;

// Quick giving panel
$quick-giving-type: columns; // simple, columns or rows
$quick-giving-donation-amount-background-colour: white;
$quick-giving-donation-amount-selected-background-colour: $brand-primary;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 1;

// Home quick giving panel
$home-quick-giving-type: overlay; // simple, adjacent or overlay
$home-quick-giving-donation-amount-background-colour: white;
$home-quick-giving-donation-amount-selected-background-colour: $brand-primary;
//$home-quick-giving-heading-colour: white; - had to do in bespoke as this variable not responsive

// Overlay homepage quick giving
//$home-quick-giving-type-overlay-items-side: right; // If type is overlay, which side do you want the copy and donation items?
$home-quick-giving-type-overlay-gradient: linear-gradient(90deg, rgba(0,0,0,0.5) 10%, rgba(0,0,0,0) 50%);

// FAQs
$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $brand-primary;
//$faq-item-hover-btn-background-colour: button-hover-state($faq-item-btn-background-colour) !default;
$faq-item-hover-btn-chevron-colour: $black;

// Listing
$listing-cards-per-row-desktop: 2 !default;
//$listing-content-max-width: 100%;

// Listed post settings
$listed-news-post-publish-date-enabled: true; //this isn't working
$listed-event-post-start-date-enabled: false;
$listed-event-post-location-enabled: false;

// Blockquote
$blockquote-background-colour: white;
$blockquote-background-gradient: none !default; // none or CSS gradient
$blockquote-margin-y: $spacer * 2 !default;
$blockquote-padding: $spacer * 2 !default;
$blockquote-text-align: left; // left, center, right
$blockquote-font-size: $font-size-base;
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $font-weight-bold;
$blockquote-line-height: $line-height-base !default;
$blockquote-colour: text-contrast($blockquote-background-colour) !default;

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: false;

// Footer
$footer-background-colour: $black;
$footer-boxes-count: 5;
$footer-prefab: 4;
$footer-link-colour: $brand-primary;
$footer-headings-font-size: $font-size-h3;
$footer-link-decoration: none;
$footer-link-hover-colour: lighten($footer-link-colour, 10%) !default;
$footer-link-hover-decoration: underline;



// Newsletter
$newsletter-background-colour: transparent;
