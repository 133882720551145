// Global 

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1.5rem; // More spacing between paras
}

b, strong {
 font-weight: $font-weight-bold;
}

// Header

header#pageHeader {
  box-shadow: rgba(0, 0, 0, 0.07) 0px 0px 1px 1px;
}

.mainLogo {
  transition: all .3s ease-in-out;
}

// Align menu to right of logo

@media (min-width: map-get($map: $breakpoints, $key: xl) + 1px) {
  .pageHeader {
    .headerContent {
      position: absolute;
    }

    .menuMain {
      padding-right: 275px;
      max-width: 100%;
      /* overwrite a fresco setting between 1201px and 1230px */
      margin: 22px auto;
    }

    &.sticky .menuMain {
      margin-bottom: 25px;
    }
  }

  .appealsPostBody,
  body.donate {

    // Change alignment on these pages where donate cta button hidden
    .menuMain {
      padding-right: 190px;
    }
  }
}

// Hide chevrons on smaller browser width

@media (max-width: map-get($map: $breakpoints, $key: xxl)) and (min-width: map-get($map: $breakpoints, $key: xl)) {

  li.level1.hasSubmenu>a:after {
    content: '';
  }

}

// Mobile menu 

@media (max-width: map-get($map: $breakpoints, $key: xl)) {

  .menuMain .topLevel a {
    font-weight: $font-weight-bold;
  }

  .level2 li a {
    padding-left: 40px !important;
    font-weight: normal !important;
  }

  .level3 li a {
    padding-left: 60px !important;
  }

}

// Search

.headerContent .header-search .searchContainer input {
  font-family: $headings-font-family;
}

// Carousel

.carouselSlideHeading {
  margin-bottom: .25em;
  line-height: 1;
}

.carouselSlideSummary {
  font-family: $headings-font-family;
  font-weight: $font-weight-bold;
  line-height: 1;
  //display: inline;
}

// @keyframes paddingAnimation {
//   0% {
//       padding: 5rem 2.5rem;
//   }

//   65% {
//     padding: 1.5rem 2.5rem;
//   }

//   100% {
//       padding: 2.5rem;
//   }
// }

// .carouselSlideDetail{
//     animation-name: paddingAnimation;
//     animation-duration: 1s;
//     animation-fill-mode: both;
//     animation-delay: 1s;
// }

// Corner detail on carousel slide detail

@media (min-width: map-get($map: $breakpoints, $key: md) + 1px) {
  .carouselSlideTitle:after {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0) 50%);
  }
}

.carouselSlideDetail {
  background-image: url($assets-path + "corner.svg"), url($assets-path + "cornerRight.svg");
  background-repeat: no-repeat, no-repeat;
  background-size: 50px;
  background-position: top left, bottom right;
  padding: 2.5em;
  margin: 40px 40px;
}

@media (max-width: map-get($map: $breakpoints, $key: md)) {
  .carouselSlideDetail {
    margin: 20px;
    background-size: 35px;
    padding: 1.5em;
  }

  .carouselSlideTitle img,
  .page-has-banner .carousel:not(.hasCaption) .carouselSlide img {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    min-height: 400px;
  }

  .carouselControls {
    display: none;
  }
}

// Home intro

.homeIntro {
  h3 {
    line-height: 1;
  }

  p {
    font-family: $font-family-base;
    font-weight: $font-weight-normal;
  }

  a.cta-button {
    text-transform: capitalize;
    text-decoration: none;
    margin-top: 10px;
  }
}

// Impact stats on home page (and inner pages: /Listing/Category/how-we-help)

.homefeaturecategory-homeboximpactstats,
.impactWrapper {
  td:last-child {
    font-family: $headings-font-family;
    font-weight: $font-weight-bold;
  }

  img {
    margin: 30px 0 20px;
    height: 100px;
  }
}

@media (min-width: map-get($map: $breakpoints, $key: lg)) {

  body.homepage .homefeaturecategory-homeboximpactstats:before {
    background-attachment: fixed;
  }

}

body:not(.homepage) .homefeaturecategory-homeboximpactstats:before {
  background-position: center center;
}

// Features

// Quick giving panel home page

.quickGivingPanel {
  /* Applies to inner page quick giving panel too */
  font-family: $headings-font-family;
}

.homefeaturecategory-homeboxquickgiving .homepage-quickgiving--copy {
  margin-bottom: 10px;
  max-width: 450px;
}

// Make quick giving heading text colour work responsively

.homefeaturecategory-homeboxquickgiving {
  h2 {
  color: $black;
  }
  // .quickGivingPanel .donateBtn {
  //   line-height: 1;
  // }
}

@media (min-width: map-get($map: $breakpoints, $key: lg)) {
  .homefeaturecategory-homeboxquickgiving {
    h2 {
      color: white;
    }
  }
}

// Home page feeds

/*.homeFeedBox1 .feedsTitle{
  @include heading-line-through(
    $colour: $brand-primary,
    $height: 1px
  );
}
.homeFeedBox2 .feedsTitle{
  @include heading-line-through(
    $colour: $grey-light,
    $height: 1px
  );
}*/

/*.homeFeed .feedsTitle{
    background-image: url($assets-path + "corner.svg"), url($assets-path + "cornerRight.svg");
    background-repeat: no-repeat, no-repeat;
    background-size:20px;
    background-position: top left, bottom right;
    font-size: $font-size-h2;
    width: auto;
    display: table;
    margin: 0 auto $spacer;
    text-align: center;
    padding: 10px 20px;
}*/

// Footer

.footerBox {
  font-family: $headings-font-family;

  h2 {
    margin-bottom: 30px;
  }

  li {
    margin-bottom: 1rem;
  }
}

.Footer-CharityNumbers {
  font-size: $font-size-small;
  text-align: center;

  img {
    max-height: 50px;
    margin: 0 10px;
  }
}

.Footer-GivingCTA {
  background-image: url($assets-path + "iconHeartSeparator.svg");
  background-size: auto 100px;
  background-position: top center;
  background-repeat: no-repeat;
  max-width: $container-max-width;
  margin: 40px auto 0 auto;
  text-align: center;
  padding: 120px 5% 120px;
  position: relative;

  p {
    font-family: $font-family-base;
  }
}

// Hide footer giving snippet on particular pages

body.donate .Footer-GivingCTA,
body.homepage .Footer-GivingCTA {
  display: none;
}

// Newsletter - snippet both in footer and inner page sidebar

#mc_embed_signup {

  .indicates-required,
  .mc-field-group label {
    font-size: $font-size-small;
    margin: 10px 0;
    display: block;
  }

  input#mc-embedded-subscribe {
    background-color: $brand-primary;
    margin: 15px 0 30px;
  }
}

.Footer-Newsletter #mc_embed_signup {
  color: white !important;
}

// Listings

// Show news publish dates - temporary while awaiting variable to be fixed

.newsListingPage .listedPost footer .publishDatesWrapper {
  display: block !important;
  font-family: $headings-font-family;
  font-weight: bold;

  time {
    color: $black;
  }

  &:before {
    color: $brand-primary;
  }
}

// Posts

// Page title over banner - recreated in bespoke as needs to work on mobile and $page-title-over-banner-breakpoint isn't yet setup to work at lower screen widths

.page-has-banner:not(.page-has-video-banner):not(.blogsPostBody) .post:not(.formWrapper) .headerWrapper {
  position: relative;
}

.page-has-banner:not(.page-has-video-banner):not(.blogsPostBody) .post:not(.formWrapper) .carousel {
  margin-bottom: calc(2rem);
}

.page-has-banner:not(.page-has-video-banner):not(.blogsPostBody) .post:not(.formWrapper) .headerText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .title {
    max-width: 600px;
    background-image: url($assets-path + "corner.svg"), url($assets-path + "cornerRight.svg");
    background-repeat: no-repeat, no-repeat;
    background-size: 50px;
    background-position: top left, bottom right;
    margin: 40px;
    padding: 2.5rem;
  }

  @media (max-width: map-get($map: $breakpoints, $key: md)) {
    .title {
      max-width: calc(100% - 40px);
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0) 50%);

    @media (max-width: map-get($map: $breakpoints, $key: md)) {
      background: rgba(0, 0, 0, .5);
    }
  }

  .title {
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    z-index: 2;
    text-align: left;
    line-height: 1;
    font-size: 3em;

    @media (max-width: map-get($map: $breakpoints, $key: md)) {
      font-size: 2em;
      margin: 20px;
    }
  }
}

.breadcrumbItem {
  font-size: .9em;
  font-family: $headings-font-family;

  &:before {
    color: $brand-primary;
  }
}

// Post content

.postContent {

  h1,
  h2,
  h3,
  h4,
  h5 {
    clear: both;
  }

  a {
    text-decoration: underline;
    //text-decoration-color: $brand-primary-accessible;
    font-weight: bold;
    text-decoration-thickness: 2px;

    &:hover {
      text-decoration-color: $brand-primary;
    }
  }

  a.button,
  a.cta-button,
  .shareList a {
    text-decoration: none;
  }

  blockquote {
    border-left: 5px solid $brand-primary;
    font-size: 1.25em;

    p {
      //display: inline;
    }

    &:before {
      content: '\f10d';
      margin-left: 5px;
      font-family: FontAwesome;
      text-align: center;
      -webkit-font-smoothing: antialiased;
      font-size: 2em;
    }

    // &:after {
    // content: '\f10e';
    // margin-left: 5px;
    // font-family: FontAwesome;
    // text-align: center;
    // -webkit-font-smoothing: antialiased;
    // }
  }

  .embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    height: auto;
    margin-left: auto;
    margin-right: auto
  }

  .embed-container iframe,
  .embed-container object,
  .embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
  }

  // Inner page quick giving panel

  .quickGivingPanel {
    margin: 40px 0;

    .donationAmountsWrapper .donationAmount {
      width: calc((100% - 60px) / 3);
      margin: 10px;
    }

    .regularPaymentLabel,
    .regularPayment {
      display: unset !important;
    }

    .button {
      margin: 20px auto;
    }
  }

  /* Client WYSIWYG Styles */
  img.image-align-left {
    margin: 0 20px 20px 0;
  }

  img.image-align-right {
    margin: 0 0 20px 20px;
  }

}

// Sidebar

ul.associatedListing {
  h3 a {
    &:hover {
      text-decoration-color: $brand-primary;
    }
  }
}

.associated {
  margin-bottom: $spacer*3;
  background-color: $grey-lighter;
  padding: 40px;
}

.associated:not(.associatedBox)>h2 {
  font-size: 1.75em;
}

.Footer---Newsletter {
  background-color: $grey-lighter;
  padding: 40px 40px 20px;

  .mc-field-group input {
    width: 100%;
  }

  .socialIcons {
    display: none;
  }
}

// Reorder sidebar elements

.postAside>.postFeeds {
  display: flex;
  flex-direction: column;
}

.associated.associatedLatestPosts {
  order: 3;
}

.associated.associatedRelatedPosts {
  order: 2;
}

// News listing

.pages-News-Category-latest-news .listedPost .listedPostText {
  // Client request - reduce space between listed text and date
  flex-grow: unset !important;
}

// Appeals listing

.donorsList {
  display: none;
}

.ft.tab {
  display: none;
}

// Appeals post

.post .contentBlock .postContent.appealContent {
  grid-column: 1 / 3;
}

.post .appealActionsWrapper {
  display: flex;
  justify-content: center;
}

// Shop

.listedProduct footer .postFooter>* {
  opacity: 1; // Overwrite Fresco which makes buttons washed out
}

// Donation form

.donationAmountFigure {
  font-family: $headings-font-family;
}

.donationComment {
  display: none;
}

.dataProtection .labelCheckBoxPair.contactViaSMS {
  display: none;
}

.formDonatePage a {
  text-decoration: underline;
  text-decoration-color: $black;
  font-weight: $font-weight-bold;
  text-decoration-thickness: 2px;

  &:hover {
    color: $black;
    text-decoration-color: $brand-primary;
  }
}

// Appeal post

.appealComments {
  display: none;
}

// Custom pages

// How we help listing page

body.how-we-help .listedPost {

  &.basic-needs>a {
    background: url($assets-path + "iconHouseWhite.svg") no-repeat center/150px;
  }

  &.womens-empowerment-and-womens-rights>a,
  &.gender-and-womens-rights>a {
    background: url($assets-path + "iconGenderRightsWhite.svg") no-repeat center/150px;
  }

  &.livelihoods>a {
    background: url($assets-path + "iconTreeWhite.svg") no-repeat center/150px;
  }

  &.humanitarian-assistance-and-disaster-risk-reduction>a {
    background: url($assets-path + "iconHeartWhite.svg") no-repeat center/150px;
  }

  &:hover {
    .banner {
      opacity: 0 !important;
    }
  }

}

// Build a balanced future appeal

.appeal-building-a-balanced-future {
  // redundant as appeal completed so this is hidden anyway

  .appealActionsWrapper {
    display: none !important;
  }

  .postContent {
    // not taking affect but appeal completed so no matter
    width: 100%;
  }

}

// Pay in your fundraising appeal - remove regular giving option from quick giving panel

body[class*="pay-in-your-fundraising"] {

  .postContent .quickGivingPanel .regularPaymentLabel,
  .postContent .quickGivingPanel .regularPayment {
    display: none !important;
  }
}

// Christmas visual appeal -- client can now apply this to any appeal by adding the category 'christmas-appeal'

@include appeal-field("PostCategory_christmas-appeal", "christmas");

body.PostCategory_christmas-appeal {
  li.breadcrumbItem a {
    color: white;
  }
  .post .appealActionsWrapper {
    justify-content: left;
  }
  .appealComments {
    display: block;
  }
  .commentsList .commentContentWrapper {
    background: $brand-primary;
    color: $black;
    &:before {
      color: $brand-primary;
    }
  }
  
}

// Font size mobile specific

@media (max-width: map-get($map: $breakpoints, $key: lg)) {
  body {
    font-size: 1.1rem;
  }

  h1.title,
  .customForm h1 {
    font-size: 2em;
  }

  .carouselSlideHeading {
    font-size: 2em;
  }

  .carouselSlideSummary {
    font-size: 1.5em;
  }
}

// Map results
.mapResultList .listedPost[class*="info"] {
  @include card-row($listing-cards-per-row-desktop);

  @include max-width(lg) {
    @include card-row($listing-cards-per-row-tablet);
  }

  @include max-width(md) {
    width: 100%;
  }
}