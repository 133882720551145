//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  );

  // Choose your card style for home feature cards
  .homeFeature {
    @include card(
      $card-details-background-colour: $grey-lighter,
      $card-hover-details-background-colour: $brand-primary,
      $card-details-padding: 40px
    );
    @include card-side-by-side;
    grid-template-columns: 49% 1fr!important;
    grid-column-gap: 2%!important;
    .homeFeatureDetailsWrapper{
      h2 a{
        text-decoration: underline;
        text-decoration-color: $brand-primary;
      }
    }
    &:nth-of-type(even) {
      @include card-side-by-side(
        $card-side-by-side-image-position: right
      );
    }
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.

//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 1
);

// Specify a card layout for each individual feed
.homeFeedBox1{
  background-color: $grey-lighter;
  @media (min-width: map-get($map: $breakpoints, $key: md)) {
    padding-bottom:$spacer*7;
  }
}
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 1
  );
  .feedItem{
    @include card(
      $card-text-align: center,
      $card-summary-enabled: false,
      $card-details-background-colour: $brand-primary,
      $card-hover-details-background-colour: $brand-primary,
      $card-heading-font-size: $font-size-large
    );
    @include card-basic(
      $prefab: 3,
    );
    a:hover{
      text-decoration: none;
    }

  }
}

.homeFeedBox2 {
  .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      &:hover h3 a{
        text-decoration: underline;
        text-decoration-color: $brand-primary;
      }
    }
  }
}

// Choose your card style for home feed cards
.feedItem {
  @include card;
  @include card-basic;
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult) {
  @include card;
  @include card-basic;
  &:hover{
    h2 a{
      text-decoration: underline;
      text-decoration-color: $brand-primary;
      &:hover{
        text-decoration-color: $brand-primary;
      }
    }
  }
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}